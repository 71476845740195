import get from 'lodash/get';
import moment from 'moment-mini';

export const shapeBlogItem = (data) => ({
	slug: data.slug,
	title: data.title,
	description: data.description,
	path: `/expert-tips/${data.slug}`,
	image: {
		url: get(data, 'image.data.attributes.url', ''),
		alternativeText: get(data, 'image.data.attributes.alternativeText', ''),
	},
});

export const shapeKioskBlogItem = (data) => {
	const item = shapeBlogItem(data);
	item.path = `/kiosk/expert-tips/${data.slug}`;

	return item;
};

export const shapeEventItem = (data) => ({
	id: data.id,
	slug: data.slug,
	title: data.title,
	location: get(data, 'location.data.attributes.name', ''),
	timing: moment(data.dateTime).format('Do MMM, hh:mm A'),
	path: `/events-webinars/${data.slug}`,
	image: {
		url: get(data, 'image.data.attributes.url', ''),
		alternativeText: get(data, 'image.data.attributes.alternativeText', ''),
	},
});
