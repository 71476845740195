import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';

// components
import GenericTableSection from '@/components/shared/GenericTableSection';
import LocationSection from '@/components/shared/LocationSection';
import PlanComparisonSection from '@/components/shared/PlanComparisonSection';
import CategorizedModuleSection from '@/components/shared/categorizedModuleSection';
import CommentSection from '@/components/shared/commentSection';
import ContactAndLocationSection from '@/components/shared/contactAndLocationSection';
import ContentSection from '@/components/shared/contentSection';
import CounterSection from '@/components/shared/counterSection';
import DownloadAppHighlight from '@/components/shared/downloadAppHighlight';
import FAQSection from '@/components/shared/faqSection';
import FormSection from '@/components/shared/formSection';
import HighlightSection from '@/components/shared/highlightSection';
import HighlightServiceSection from '@/components/shared/highlightServiceSection';
import HighlightStepsSection from '@/components/shared/highlightStepsSection';
import IFrameSection from '@/components/shared/iFrameSection';
import ImageGallerySection from '@/components/shared/imageGallerySection';
import InfoNote from '@/components/shared/infoNote';
import ListingSection from '@/components/shared/listingSection';
import LiveCounsellingSection from '@/components/shared/liveCounsellingSection';
import LoginCTA from '@/components/shared/loginCTA';
import NavigationSection from '@/components/shared/navigationSection';
import OverviewSection from '@/components/shared/overviewSection';
import PerksAndBenefitsSection from '@/components/shared/perksAndBenefitsSection';
import PlanDetailSection from '@/components/shared/planDetailSection';
import PlanListSection from '@/components/shared/planListSection';
import PlanOfferingSection from '@/components/shared/planOfferingSection';
import RecentlyAddedSection from '@/components/shared/recentlyAddedSection';
import ServiceSection from '@/components/shared/servicesSection';
import TableSection from '@/components/shared/tableSection';
import TestimonialSection from '@/components/shared/testimonialSection';
import TimeTableSection from '@/components/shared/timeTableSection';
import Timeline from '@/components/shared/timeline';
import VideoGallerySection from '@/components/shared/videoGallerySection';
import VideoSection from '@/components/shared/videoSection';
import WalkthroughSection from '@/components/shared/walkthroughSection';
import AboutUsPageBannerSection from '@/modules/about-us-page/banner-section';
import CareerPageBannerSection from '@/modules/career-page/banner-section';
import CareerPageFormSection from '@/modules/career-page/form-section';
import EventPageBannerSection from '@/modules/event-page/banner-section';
import EventDetailPageBannerSection from '@/modules/event-page/detail-page-banner-section';
import EventGroupBannerSection from '@/modules/event-page/groupBannerSection';
import RelatedDataSection from '@/modules/expenseCalculatorPage/relatedDataSection';
import HomePageBannerSection from '@/modules/home-page/banner-section';
import LandingPageBannerSection from '@/modules/landing-page/banner-section';
import ProductPageBannerSection from '@/modules/productPage/bannerSection';
import RedirectionSection from '@/modules/redirection-page/redirectionSection';
import ScholarshipDetailBannerSection from '@/modules/scholarshipPage/detailBannerSection';
import ScholarshipDataList from '@/modules/scholarshipPage/scholarshipDataList';
import ThankYouPageMainSection from '@/modules/thankYou/mainSection';
import UniversityBannerSection from '@/modules/unversityPage/bannerSection';
import UniversityDataListSection from '@/modules/unversityPage/dataListSection';
import UniversityDetailBannerSection from '@/modules/unversityPage/detailBannerSection';

// mui
import { Box } from '@mui/material';

// utils
import { getNavigationSectionCardList, getSectionId } from './util';

// styles
import useStyles from './style';

// eslint-disable-next-line react/display-name
const getComponentWithOptions = (options, analyticsProps) => (component) => {
	switch (component.__component) {
		// Landing-Page Comps
		case 'landing-page.landing-page-banner-section': {
			return (
				<LandingPageBannerSection
					pageSlug={options.page.slug}
					title={component.title}
					highlightTitle={component.highlightTitle}
					titleFontSize={component.titleFontSize}
					titleImage={component.titleImage}
					description={component.description}
					mobileDescription={component.mobileDescription}
					formId={options.form.pageFormId}
					form={options.form.pageForm}
					formPageConstants={options.form.constants}
					formPageConfig={options.form.config}
					useOtpFlowForForm={options.form.useOtpFlowForForm}
					paymentDetail={options.form.paymentDetail}
					analyticsProps={analyticsProps}
				/>
			);
		}

		// // Career-Page Comps
		case 'career-page.career-page-banner-section': {
			return (
				<CareerPageBannerSection
					title={component.title}
					highlightTitle={component.highlightTitle}
					description={component.description}
					titleFontSize={component.titleFontSize}
					bannerBackground={component.bannerBackground}
					titleImage={component.titleImage}
					buttonText={component.buttonText}
					analyticsProps={analyticsProps}
				/>
			);
		}

		case 'career-page.career-page-form-section': {
			return (
				<CareerPageFormSection
					pageSlug={options.page.slug}
					formTitle={component.title}
					titleFontSize={component.titleFontSize}
					formDescription={component.description}
					form={component.form}
					formIcon={component.titleIcon}
					formPageConstants={options.form.constants}
					formPageConfig={options.form.config}
					useOtpFlowForForm={options.form.useOtpFlowForForm}
					paymentDetail={options.form.paymentDetail}
					analyticsProps={analyticsProps}
				/>
			);
		}

		// Home-Page Comps
		case 'home-page.home-page-banner-section': {
			return (
				<HomePageBannerSection
					title={component.title}
					highlightTitle={component.highlightTitle}
					titleImages={component.titleImages}
					description={component.description}
					mobileDescription={component.mobileDescription}
					androidAppIcon={component.androidAppIcon}
					iosAppIcon={component.iosAppIcon}
					analyticsProps={analyticsProps}
					titleFontSize={component.titleFontSize}
				/>
			);
		}

		// Contact-Us-Page comps
		case 'contact-us-page.location': {
			return (
				<LocationSection
					title={component.title}
					titleFontSize={component.titleFontSize}
					description={component.description}
					locations={component.locations.filter(
						(location) => location.locationDetails
					)}
					analyticsProps={analyticsProps}
				/>
			);
		}
		// Event-Page Comps
		case 'event-page.event-page-banner-section': {
			return (
				<EventPageBannerSection
					pageSlug={options.page.slug}
					title={component.title}
					highlightTitle={component.highlightTitle}
					titleImage={component.titleImage}
					titleFontSize={component.titleFontSize}
					description={component.description}
					mobileDescription={component.mobileDescription}
					rating={component.rating}
					timer={component.timer}
					endTime={component.endTime}
					ctaButton={component.ctaButton}
					formId={options.form.pageFormId}
					form={component.form}
					formPageConstants={component.formPageConstants}
					formPageConfig={component.formPageConfig}
					useOtpFlowForForm={component.useOtpFlowForForm}
					paymentDetail={component.paymentDetail}
					authUserForm={component.authUserForm}
					analyticsProps={analyticsProps}
				/>
			);
		}

		case 'event-page.event-detail-page-banner-section': {
			return (
				<EventDetailPageBannerSection
					pageSlug={options.page.slug}
					title={component.title}
					highlightTitle={component.highlightTitle}
					titleFontSize={component.titleFontSize}
					titleImage={component.titleImage}
					timer={component.timer}
					endTime={component.endTime}
					dateTime={component.dateTime}
					description={component.description}
					mobileDescription={component.mobileDescription}
					formId={options.form.pageFormId}
					form={options.form.pageForm}
					formPageConstants={options.form.constants}
					formPageConfig={options.form.config}
					useOtpFlowForForm={options.form.useOtpFlowForForm}
					paymentDetail={options.form.paymentDetail}
					globalFormConfig={
						options.cmsGlobalData && options.cmsGlobalData.globalFormConfig
					}
					searchQueryValues={options.searchQueryValues}
					authUserForm={options.form.authUserForm}
					analyticsProps={analyticsProps}
				/>
			);
		}

		case 'event-page.event-group-banner-section': {
			return component.event.length === 0 ? null : (
				<EventGroupBannerSection
					titleFontSize={component.titleFontSize}
					pageSlug={options.page.slug}
					event={component.event}
					interval={component.interval}
					analyticsProps={analyticsProps}
				/>
			);
		}

		// About-Us-Page Comps
		case 'about-us-page.about-us-page-banner-section': {
			return (
				<AboutUsPageBannerSection
					pageSlug={options.page.slug}
					title={component.title}
					highlightTitle={component.highlightTitle}
					titleFontSize={component.titleFontSize}
					titleImages={component.titleImages}
					description={component.description}
					mobileDescription={component.mobileDescription}
					variant={component.variant}
					imageAlignment={component.imageAlignment}
					ctaButton={component.ctaButton}
					formId={options.form.pageFormId}
					form={options.form.pageForm}
					formPageConstants={options.form.constants}
					formPageConfig={options.form.config}
					useOtpFlowForForm={options.form.useOtpFlowForForm}
					paymentDetail={options.form.paymentDetail}
					globalFormConfig={
						options.cmsGlobalData && options.cmsGlobalData.globalFormConfig
					}
					searchQueryValues={options.searchQueryValues}
					analyticsProps={analyticsProps}
				/>
			);
		}

		// Thank-You-Page Comps
		case 'thank-you-page.thank-you-section': {
			return (
				<ThankYouPageMainSection
					title={component.title}
					titleFontSize={component.titleFontSize}
					description={component.description}
					subDescription={component.subDescription}
					buttonActions={component.buttonActions}
					zohoFormRequired={component.zohoFormRequired}
					searchQueryValues={options.searchQueryValues}
				/>
			);
		}

		// Redirection-Page Comps
		case 'shared.redirection-section': {
			return (
				<RedirectionSection
					config={component.config}
					searchQueryValues={options.searchQueryValues}
				/>
			);
		}

		// University-Us-Page Comps
		case 'university-page.university-detail-page-banner-section': {
			return (
				<UniversityDetailBannerSection
					title={component.title}
					highlightTitle={component.highlightTitle}
					subTitle={component.subTitle}
					titleFontSize={component.titleFontSize}
					titleIcon={component.titleIcon}
					titleImages={component.titleImages}
					stats={component.stats}
					button={component.button}
					analyticsProps={analyticsProps}
				/>
			);
		}

		case 'university-page.university-page-banner-section': {
			return (
				<UniversityBannerSection
					title={component.title}
					highlightTitle={component.highlightTitle}
					titleFontSize={component.titleFontSize}
					description={component.description}
					analyticsProps={analyticsProps}
				/>
			);
		}

		case 'scholarship-page.scholarship-section': {
			return (
				<ScholarshipDataList
					cardList={component.cardList}
					searchQueryValues={options.searchQueryValues}
					universityId={component.universityId}
					universityName={component.universityName}
					analyticsProps={analyticsProps}
				/>
			);
		}

		case 'scholarship-page.scholarship-page-detail-banner-section': {
			return (
				<ScholarshipDetailBannerSection
					title={component.title}
					titleFontSize={component.titleFontSize}
					image={component.image}
					universityId={component.universityId}
					analyticsProps={analyticsProps}
				/>
			);
		}

		case 'univserity-data-list-section': {
			return (
				<UniversityDataListSection
					highlightTitle={component.highlightTitle}
					title={component.title}
					subTitle={component.subTitle}
					titleFontSize={component.titleFontSize}
					cardList={component.cardList}
					programLevelFilterOptions={component.programLevelFilterOptions}
					theme={component.theme}
					link={component.link}
					searchQueryValues={options.searchQueryValues}
					requireAuthBeforeRedirect={component.requireAuthBeforeRedirect}
				/>
			);
		}

		// Product-Page Comps
		case 'product-page.product-page-banner-section': {
			return (
				<ProductPageBannerSection
					pageSlug={options.page.slug}
					title={component.title}
					highlightTitle={component.highlightTitle}
					subTitle={component.subTitle}
					titleFontSize={component.titleFontSize}
					titleImages={component.titleImages}
					buttonLabel={component.buttonLabel}
					formId={options.form.pageFormId}
					form={options.form.pageForm}
					formPageConstants={options.form.constants}
					formPageConfig={options.form.config}
					useOtpFlowForForm={options.form.useOtpFlowForForm}
					paymentDetail={options.form.paymentDetail}
					globalFormConfig={
						options.cmsGlobalData && options.cmsGlobalData.globalFormConfig
					}
					searchQueryValues={options.searchQueryValues}
					analyticsProps={analyticsProps}
				/>
			);
		}

		// Shared Comps
		case 'shared.video-section': {
			return (
				<VideoSection
					title={component.title}
					subTitle={component.subtitle}
					titleFontSize={component.titleFontSize}
					videoLink={component.videoLink}
					autoPlay={component.autoPlay}
					analyticsProps={analyticsProps}
				/>
			);
		}

		case 'shared.testimonial-section':
			return (
				<TestimonialSection
					title={component.title}
					subTitle={component.subtitle}
					titleFontSize={component.titleFontSize}
					cardListTheme={component.cardListTheme}
					cardList={component.cardList}
					analyticsProps={analyticsProps}
				/>
			);

		case 'shared.services-section': {
			return (
				<ServiceSection
					title={component.title}
					highlightTitle={component.highlightTitle}
					subTitle={component.subtitle}
					titleFontSize={component.titleFontSize}
					cardListTheme={component.cardListTheme}
					cardList={component.cardList}
					analyticsProps={analyticsProps}
				/>
			);
		}

		case 'shared.highlight-section': {
			return (
				<HighlightSection
					title={component.title}
					highlightTitle={component.highlightTitle}
					subTitle={component.subtitle}
					titleFontSize={component.titleFontSize}
					cardListTheme={component.cardListTheme}
					cardList={component.cardList}
					analyticsProps={analyticsProps}
				/>
			);
		}
		case 'shared.login-cta-section': {
			return (
				<LoginCTA
					pageSlug={options.page.slug}
					highlightTitle={component.highlightTitle}
					title={component.title}
					subTitle={component.subTitle}
					titleFontSize={component.titleFontSize}
					mobileTitle={component.mobileTitle}
					mobileSubTitle={component.mobileSubTitle}
					ctaButton={component.ctaButton}
					formId={options.form.pageFormId}
					form={options.form.pageForm}
					formPageConstants={options.form.constants}
					formPageConfig={options.form.config}
					useOtpFlowForForm={options.form.useOtpFlowForForm}
					paymentDetail={options.form.paymentDetail}
					globalFormConfig={
						options.cmsGlobalData && options.cmsGlobalData.globalFormConfig
					}
					searchQueryValues={options.searchQueryValues}
					analyticsProps={analyticsProps}
				/>
			);
		}

		case 'shared.counter-section': {
			return (
				<CounterSection
					title={component.title}
					description={component.description}
					titleImage={component.titleImage}
					titleFontSize={component.titleFontSize}
					cardListTheme={component.cardListTheme}
					cardList={component.cardList}
					analyticsProps={analyticsProps}
				/>
			);
		}
		case 'shared.listing-section': {
			let showDayFilter = component.listType === 'event';

			if ('listingSectionShowDayFilter' in component) {
				showDayFilter = component.listingSectionShowDayFilter;
			}

			return (
				<ListingSection
					pageSlug={options.page.slug}
					title={component.title}
					highlightTitle={component.highlightTitle}
					subTitle={component.subTitle}
					titleFontSize={component.titleFontSize}
					cardListTitle={component.cardListTitle}
					cardListHighlightTitle={component.cardListHighlightTitle}
					listType={component.listType}
					itemCount={component.itemCount}
					paginated={component.paginated}
					cardList={component.cardList}
					categories={component.categories}
					tags={component.tags}
					locations={component.locations}
					filters={get(options, 'filters.tag', null)}
					showDayFilter={showDayFilter}
					loginCTA={options.loginCTA}
					formId={options.form.pageFormId}
					form={options.form.pageForm}
					formPageConstants={options.form.constants}
					formPageConfig={options.form.config}
					useOtpFlowForForm={options.form.useOtpFlowForForm}
					paymentDetail={options.form.paymentDetail}
					globalFormConfig={
						options.cmsGlobalData && options.cmsGlobalData.globalFormConfig
					}
					searchQueryValues={options.searchQueryValues}
					link={component.link}
					analyticsProps={analyticsProps}
				/>
			);
		}

		case 'shared.walkthrough-section': {
			return (
				<WalkthroughSection
					title={component.title}
					highlightTitle={component.highlightTitle}
					titleFontSize={component.titleFontSize}
					alignImage={component.alignImage}
					description={component.description}
					titleImage={component.titleImage}
					analyticsProps={analyticsProps}
				/>
			);
		}

		case 'shared.perks-and-benefits-section': {
			return (
				<PerksAndBenefitsSection
					title={component.title}
					highlightTitle={component.highlightTitle}
					description={component.description}
					titleFontSize={component.titleFontSize}
					perk={component.perk}
					analyticsProps={analyticsProps}
				/>
			);
		}

		case 'shared.timeline': {
			return (
				<Timeline
					titleHighlighted={component.titleHighlighted}
					title={component.title}
					titleFontSize={component.titleFontSize}
					description={component.description}
					cardList={component.cardList}
					analyticsProps={analyticsProps}
				/>
			);
		}

		case 'shared.form-section': {
			return (
				<FormSection
					width={component.fullWidth ? '100%' : '90%'}
					pageSlug={options.page.slug}
					highlightTitle={component.highlightTitle}
					title={component.title}
					subTitle={component.subTitle}
					slotBookingForm={component.slotBookingForm}
					form={component.form}
					formPageConstants={options.form.constants}
					titleFontSize={component.titleFontSize}
					formPageConfig={options.form.config}
					useOtpFlowForForm={options.form.useOtpFlowForForm}
					paymentDetail={options.form.paymentDetail}
					globalFormConfig={
						options.cmsGlobalData && options.cmsGlobalData.globalFormConfig
					}
					searchQueryValues={options.searchQueryValues}
					analyticsProps={analyticsProps}
				/>
			);
		}

		case 'shared.content': {
			return (
				<ContentSection
					title={component.title}
					titleFontSize={component.titleFontSize}
					body={component.body}
					styled={component.styled}
					alignment={component.alignment}
				/>
			);
		}
		case 'shared.info-note': {
			return <InfoNote infoNote={component.infoNote} />;
		}

		case 'shared.video-gallery-section': {
			return (
				<VideoGallerySection
					title={component.title}
					highlightTitle={component.highlightTitle}
					subTitle={component.subTitle}
					titleFontSize={component.titleFontSize}
					cardList={component.cardList}
					analyticsProps={analyticsProps}
				/>
			);
		}

		case 'shared.image-gallery-section': {
			return (
				<ImageGallerySection
					highlightTitle={component.highlightTitle}
					title={component.title}
					subTitle={component.subTitle}
					titleFontSize={component.titleFontSize}
					imageSize={component.imageSize}
					imageShape={component.imageShape}
					cardList={component.cardList}
					analyticsProps={analyticsProps}
				/>
			);
		}

		case 'shared.download-app-highlight': {
			return (
				<DownloadAppHighlight
					highlightTitle={component.highlightTitle}
					title={component.title}
					titleImage={component.titleImage}
					appIconLabel={component.appIconLabel}
					androidAppIcon={component.androidAppIcon}
					iosAppIcon={component.iosAppIcon}
					analyticsProps={analyticsProps}
					titleFontSize={component.titleFontSize}
				/>
			);
		}

		case 'shared.recently-added-section': {
			return (
				<RecentlyAddedSection
					title={component.title}
					highlightTitle={component.highlightTitle}
					subTitle={component.subTitle}
					titleFontSize={component.titleFontSize}
					slider={component.slider}
					itemCount={component.itemCount}
					alignment={component.alignment}
					listType={component.listType}
					link={component.link}
					exclude={options.page.slug}
					analyticsProps={analyticsProps}
				/>
			);
		}

		case 'shared.comment-section': {
			return (
				<CommentSection
					pageId={options.page.id}
					highlightTitle={component.highlightTitle}
					title={component.title}
					titleFontSize={component.titleFontSize}
					source={component.source}
					itemCount={component.itemCount}
					analyticsProps={analyticsProps}
				/>
			);
		}

		case 'shared.highlight-steps-section': {
			return (
				<HighlightStepsSection
					highlightTitle={component.highlightTitle}
					title={component.title}
					subTitle={component.subtitle}
					titleFontSize={component.titleFontSize}
					cardList={component.cardList}
					titleImage={component.titleImage}
					analyticsProps={analyticsProps}
				/>
			);
		}

		case 'shared.highlight-service-section': {
			return (
				<HighlightServiceSection
					pageSlug={options.page.slug}
					highlightTitle={component.highlightTitle}
					title={component.title}
					subTitle={component.subTitle}
					titleFontSize={component.titleFontSize}
					cardList={component.cardList}
					formId={options.form.pageFormId}
					form={options.form.pageForm}
					formPageConstants={options.form.constants}
					formPageConfig={options.form.config}
					useOtpFlowForForm={options.form.useOtpFlowForForm}
					paymentDetail={options.form.paymentDetail}
					globalFormConfig={
						options.cmsGlobalData && options.cmsGlobalData.globalFormConfig
					}
					searchQueryValues={options.searchQueryValues}
					analyticsProps={analyticsProps}
				/>
			);
		}

		case 'shared.i-frame-section': {
			return get(options, 'page.showIFrameSection', true) ? (
				<IFrameSection
					highlightTitle={component.highlightTitle}
					title={component.title}
					subTitle={component.subTitle}
					titleFontSize={component.titleFontSize}
					link={component.link}
					width={component.width}
					height={component.height}
					queryKeyConfig={component.queryKeyConfig}
					searchQueryValues={options.searchQueryValues}
				/>
			) : null;
		}

		case 'shared.contact-and-location-section': {
			return (
				<ContactAndLocationSection
					highlightTitle={component.highlightTitle}
					title={component.title}
					subTitle={component.subTitle}
					titleFontSize={component.titleFontSize}
					contactEmail={component.contactEmail}
					contactNumber={component.contactNumber}
					mapEmbedUrl={component.mapEmbedUrl}
					socialIconActions={component.socialIconActions}
				/>
			);
		}

		case 'shared.overview-section': {
			return (
				<OverviewSection
					highlightTitle={component.highlightTitle}
					title={component.title}
					subTitle={component.subTitle}
					titleFontSize={component.titleFontSize}
					body={component.body}
					shortDescriptionLetterCount={component.shortDescriptionLetterCount}
					shortDescriptionLetterCountMobile={
						component.shortDescriptionLetterCountMobile
					}
				/>
			);
		}

		case 'shared.table-section': {
			return (
				<TableSection
					title={component.title}
					highlightTitle={component.highlightTitle}
					subTitle={component.subTitle}
					titleFontSize={component.titleFontSize}
					body={component.body}
					styled={component.styled}
					tableTextAlignment={component.tableTextAlignment}
					rowBorder={component.rowBorder}
					columnBorder={component.columnBorder}
				/>
			);
		}

		case 'generic-table-section': {
			return (
				<GenericTableSection
					title={component.title}
					highlightTitle={component.highlightTitle}
					subTitle={component.subTitle}
					titleFontSize={component.titleFontSize}
					styled={component.styled}
					tableBorder={component.tableBorder}
					rowBorder={component.rowBorder}
					columnBorder={component.columnBorder}
					rows={component.rows}
					headings={component.headings}
					columnsAlignment={component.columnsAlignment}
					link={component.link}
					linkText={component.linkText}
				/>
			);
		}

		case 'shared.navigation-section': {
			return <NavigationSection cardList={component.cardList} />;
		}

		case 'shared.categorized-module-section': {
			return (
				<CategorizedModuleSection
					highlightTitle={component.highlightTitle}
					title={component.title}
					subTitle={component.subTitle}
					titleFontSize={component.titleFontSize}
					cardList={component.cardList}
				/>
			);
		}

		case 'shared.faq-section': {
			return (
				<FAQSection
					highlightTitle={component.highlightTitle}
					title={component.title}
					titleFontSize={component.titleFontSize}
					subTitle={component.subTitle}
					cardList={component.cardList}
					numbered={component.numbered}
				/>
			);
		}

		case 'shared.plan-comparison-section': {
			return (
				<PlanComparisonSection
					highlightTitle={component.highlightTitle}
					title={component.title}
					subTitle={component.subTitle}
					titleFontSize={component.titleFontSize}
					cardList={component.cardList}
					analyticsProps={analyticsProps}
				/>
			);
		}

		case 'shared.plan-offering-section': {
			return (
				<PlanOfferingSection
					highlightTitle={component.highlightTitle}
					title={component.title}
					subTitle={component.subTitle}
					cardList={component.cardList}
					titleFontSize={component.titleFontSize}
				/>
			);
		}

		case 'shared.plan-detail-section': {
			return (
				<PlanDetailSection
					highlightTitle={component.highlightTitle}
					title={component.title}
					titleFontSize={component.titleFontSize}
					subTitle={component.subTitle}
					cardList={component.cardList}
					interval={component.interval}
				/>
			);
		}

		case 'shared.plan-list-section': {
			return (
				<PlanListSection
					highlightTitle={component.highlightTitle}
					title={component.title}
					subTitle={component.subTitle}
					titleFontSize={component.titleFontSize}
					cardList={component.cardList}
					button={component.button}
					analyticsProps={analyticsProps}
				/>
			);
		}

		case 'shared.time-table-section': {
			return (
				<TimeTableSection
					titleHeading={component.titleHeading}
					highlightTitleHeading={component.highlightTitleHeading}
					title={component.title}
					highlightTitle={component.highlightTitle}
					titleHeadingFontSize={component.titleHeadingFontSize}
					targetCountryLabel={component.targetCountryLabel}
					targetIntakeLabel={component.targetIntakeLabel}
					testTakenLabel={component.testTakenLabel}
					buttonText={component.buttonText}
					disclaimerText={component.disclaimerText}
					styled={component.styled}
					analyticsProps={analyticsProps}
				/>
			);
		}
		case 'shared.live-counselling-plug-component': {
			return (
				<LiveCounsellingSection
					cardList={component.cardList}
					cityLabel={component.cityLabel}
					educationBacklogsLabel={component.educationBacklogsLabel}
					educationScoreLabel={component.educationScoreLabel}
					emailLabel={component.emailLabel}
					title={component.title}
					highlightTitle={component.highlightTitle}
					titleFontSize={component.titleFontSize}
					firstNameLabel={component.firstNameLabel}
					gapEducationLabel={component.gapEducationLabel}
					highestLevelEducationLabel={component.highestLevelEducationLabel}
					labelOnNavigation={component.labelOnNavigation}
					lastNameLabel={component.lastNameLabel}
					mobileNumberLabel={component.mobileNumberLabel}
					selectDateLabel={component.selectDateLabel}
					selectTimeLabel={component.selectTimeLabel}
					targetCountryLabel={component.targetCountryLabel}
					targetIntakeLabel={component.targetIntakeLabel}
					testNameLabel={component.testNameLabel}
					testScoreLabel={component.testScoreLabel}
					testTakenLabel={component.testTakenLabel}
					styled={component.styled}
					analyticsProps={analyticsProps}
					constants={component.constants}
					formPageConstants={options.form.constants}
				/>
			);
		}

		case 'blog-page.related-blogs': {
			return isEmpty(component.blogs) ? null : (
				<RelatedDataSection
					type={'blog'}
					title={component.title}
					description={component.description}
					blogs={component.blogs}
					analyticsProps={analyticsProps}
					countries={options.countries}
				/>
			);
		}

		case 'event-page.related-events': {
			return isEmpty(component.events) ? null : (
				<RelatedDataSection
					type={'event'}
					title={component.title}
					description={component.description}
					events={component.events}
					analyticsProps={analyticsProps}
					countries={options.countries}
					city={options.city}
				/>
			);
		}

		default:
			return null;
	}
};
export default function DynamicZone({
	components,
	sectionClassName,
	...props
}) {
	const { classes, cx } = useStyles(props);

	const getComponentByType = getComponentWithOptions(props, {
		...props.analyticsProps,
		mainSection: 'DynamicZone',
	});

	return (
		<Box className={classes.dynamicZoneContainer}>
			{components.map((component, index) => {
				if (component.__component === 'shared.navigation-section') {
					component.cardList = getNavigationSectionCardList(components);
				}

				let Component = getComponentByType(component);
				if (Component === null) return null;

				return (
					<Box
						key={index}
						id={getSectionId(
							component.__component,
							component.id,
							component.labelOnNavigation
						)}
						className={cx(
							classes.scrollMargin,
							sectionClassName || classes.dynamicZoneSection
						)}
					>
						{Component}
					</Box>
				);
			})}
		</Box>
	);
}

DynamicZone.propTypes = {
	components: PropTypes.array.isRequired,
	sectionClassName: PropTypes.string,
	page: PropTypes.object,
	form: PropTypes.object,
	cmsGlobalData: PropTypes.object,
	searchQueryValues: PropTypes.object,
	analyticProps: PropTypes.object,
};

DynamicZone.defaultProps = {
	components: [],
	analyticProps: {},
};
