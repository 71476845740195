import PropTypes from 'prop-types';

// components
import TitleSection from '@/components/shared/titleSection';
import RelatedBlogsSection from './blogList';
import RelatedEventsSection from './eventList';

// mui
import { Box, Typography } from '@mui/material';

// style
import useStyles from './style';

export default function RelatedDataSection({
	type,
	events,
	blogs,
	title,
	useCustomTitle,
	description,
	countries,
	city,
	analyticsProps,
}) {
	const { theme, classes } = useStyles();

	let Section,
		sectionProps = {
			backgroundColor: theme.palette.common.white,
			mobileBackgroundColor: theme.palette.common.lightGrey,
			tabletBackgroundColor: theme.palette.common.lightGrey,
			countries: countries,
			city: city,
			analyticsProps: analyticsProps,
		};

	switch (type) {
		case 'blog':
			Section = RelatedBlogsSection;
			sectionProps.blogs = blogs;
			break;

		default:
			Section = RelatedEventsSection;
			sectionProps.events = events;
			break;
	}

	return (
		<>
			<Box className={classes.titleWrap}>
				{useCustomTitle ? (
					<>
						<Typography component="h2" className={classes.title}>
							{title}
						</Typography>

						{description && (
							<Typography
								variant="subtitle1"
								className={classes.descriptionText}
							>
								{description}
							</Typography>
						)}
					</>
				) : (
					<TitleSection title={title} subTitle={description} />
				)}
			</Box>
			{Section ? <Section {...sectionProps} /> : null}
		</>
	);
}

RelatedDataSection.propTypes = {
	type: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	useCustomTitle: PropTypes.bool,
	description: PropTypes.string,
	events: PropTypes.array,
	blogs: PropTypes.array,
	analyticsProps: PropTypes.object,
};
RelatedDataSection.defaultProps = {
	analyticsProps: {},
	events: [],
	blogs: [],
	useCustomTitle: false,
};
