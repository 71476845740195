import { makeStyles } from 'tss-react/mui';
const itemsPerRow = { desktop: 4, largeTablet: 3, tablet: 2 };
const cardWidthDesktop = `${(100 / (itemsPerRow.desktop || 4)).toFixed(2)}%`;
const cardWidthTablet = `${(100 / (itemsPerRow.tablet || 2)).toFixed(2)}%`;
const cardWidthLargetablet = `${(100 / (itemsPerRow.largeTablet || 3)).toFixed(
	2
)}%`;
const useStyles = makeStyles()((theme, props = {}) => ({
	container: {
		display: 'flex',
		flexDirection: 'column',
		backgroundColor: props.backgroundColor || theme.palette.common.lightGrey,
	},
	titleWrap: {
		marginBottom: theme.spacing(2),
	},
	title: {
		...theme.typography.h4,
		[theme.breakpoints.down('sm')]: {
			fontSize: theme.typography.h5.fontSize,
		},
	},
	descriptionText: {
		color: theme.palette.text.disabled,
		width: theme.spacing(80),
		margin: '0 auto',
		[theme.breakpoints.down('sm')]: {
			width: 'unset',
		},
	},
	blogListWrap: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'center',

		margin: '-24px 0 0 -24px',
		'& > *': { margin: '24px 0 0 24px' },
	},
	eventListWrap: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'center',

		margin: '-16px 0 0 -16px',
		'& > *': { margin: '16px 0 0 16px' },
	},
	blogCardWrap: {
		flexBasis: 'calc(33.33% - 24px)',
		minWidth: 0,

		[theme.breakpoints.down('md')]: {
			flexBasis: 'calc(50% - 24px)',
		},
		[theme.breakpoints.down('sm')]: {
			flexBasis: '100%',
		},
	},
	eventCardWrap: {
		minWidth: 0,
		flexBasis: `calc(${cardWidthDesktop} - 16px)`,

		[theme.breakpoints.down('lg')]: {
			flexBasis: `calc(${cardWidthLargetablet} - 16px)`,
		},
		[theme.breakpoints.down('md')]: {
			flexBasis: `calc(${cardWidthTablet} - 16px)`,
		},
		[theme.breakpoints.down('sm')]: {
			flexBasis: '100%;',
		},
	},
	link: {
		display: 'inline-block',
		width: 'fit-content',
		margin: '0 auto',
	},
	button: {
		borderRadius: '24px',
		marginTop: theme.spacing(3),
	},
	cardContainer: {
		justifyContent: 'center',
	},
}));

export default useStyles;
