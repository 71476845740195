import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment-mini';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

// components
const EventCardDesktop = dynamic(() =>
	import('@/components/shared/listingSection/partials/eventCard')
);
const EventCardMobile = dynamic(() =>
	import('@/components/shared/recentlyAddedSection/partials/itemCard')
);

// material
import { ArrowRightAlt as ArrowRightAltIcon } from '@mui/icons-material';
import { Box, Button } from '@mui/material';

// action
import { goToExternalRoute, goToRoute } from '@/store/actions';
import * as eventActions from '@/utils/analytics';
import { getEventPageUrl } from '@/utils/url/compass';

// style
import useStyles from './style';

const eventListPagePath = '/events-webinars',
	viewAllButtonLabel = 'View All';

export default function RelatedEventsSection(props) {
	const { classes } = useStyles(props);
	const isMobile = useSelector((state) => state.common.isMobile);

	const dispatch = useDispatch();

	const registeredEvent =
		useSelector((state) => state.event.registeredEvents) || {};

	const location = useSelector((state) => state.router.location);
	let searchParams = new URLSearchParams(location.search);

	let viewAllLink = eventListPagePath;
	if (!isEmpty(props.countries) || !isEmpty(props.city)) {
		let query = '';
		if (!isEmpty(props.countries)) {
			query = `?countries=${encodeURIComponent(props.countries)}`;
		}
		if (!isEmpty(props.city)) {
			query = `${query ? `${query}&` : '?'}cities=${encodeURIComponent(
				props.city
			)}`;
		}
		viewAllLink = `${eventListPagePath}${query}`;
	}

	const analyticsProps = {
		...props.analyticsProps,
		section: 'RELATED_EVENTS_SECTION',
	};

	let OnClickViewAll = () => {
		eventActions.trackEvent('LINK_CLICKED', {
			...analyticsProps,
			eventLabel: viewAllButtonLabel,
			eventCategory: 'NAVIGATION',
			eventAction: 'CLICKED',
			landingPageUrl: viewAllLink,
		});
	};

	const handleButtonClick = ({ id, slug, title }) => {
		const registered = registeredEvent[id.toString()],
			link = registered ? getEventPageUrl(id) : `/events-webinars/${slug}`;

		eventActions.trackEvent('LINK_CLICKED', {
			...analyticsProps,
			eventCategory: 'NAVIGATION',
			eventAction: 'CLICKED',
			subSection: title,
			landingPageUrl: link,
		});

		if (registered) {
			goToExternalRoute(link, {}, searchParams);
		} else {
			dispatch(goToRoute(link, {}, searchParams));
		}
	};

	const EventCard = isMobile ? EventCardMobile : EventCardDesktop;

	return (
		<Box className={classes.container}>
			<Box className={classes.eventListWrap}>
				{props.events
					.slice(0, isMobile ? 3 : props.events.length)
					.map((event, index) => {
						return (
							<Box
								key={index}
								className={classes.eventCardWrap}
								onClick={() => handleButtonClick(event)}
							>
								<EventCard
									slug={event.slug}
									title={event.title}
									handleClick={() => handleButtonClick(event)}
									timing={moment(event.dateTime).format('Do MMM, hh:mm A')}
									location={get(event, 'location.data.attributes.name')}
									image={event.image}
									registered={registeredEvent[event.id.toString()]}
									eventType={get(
										registeredEvent[event.id.toString()],
										'event_type'
									)}
									uniqueId={get(
										registeredEvent[event.id.toString()],
										'unique_id'
									)}
								/>
							</Box>
						);
					})}
			</Box>

			<Link href={viewAllLink}>
				<a className={classes.link}>
					<Button
						variant="outlined"
						color="primary"
						size="large"
						className={classes.button}
						endIcon={<ArrowRightAltIcon />}
						onClick={OnClickViewAll}
					>
						{viewAllButtonLabel}
					</Button>
				</a>
			</Link>
		</Box>
	);
}

RelatedEventsSection.propTypes = {
	events: PropTypes.array.isRequired,
	backgroundColor: PropTypes.string,
	mobileBackgroundColor: PropTypes.string,
	tabletBackgroundColor: PropTypes.string,
	analyticsProps: PropTypes.object,
};
RelatedEventsSection.defaultProps = {
	backgroundColor: '',
	mobileBackgroundColor: '',
	tabletBackgroundColor: '',
	analyticsProps: {},
};
