import isEmpty from 'lodash/isEmpty';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// material
import { ArrowRightAlt as ArrowRightAltIcon } from '@mui/icons-material';
import { Box, Button } from '@mui/material';

// action
import * as eventActions from '@/utils/analytics';

// style
import useStyles from './style';

// component
const BlogCardDesktop = dynamic(() =>
	import('@/components/shared/listingSection/partials/blogCard')
);
const BlogCardMobile = dynamic(() =>
	import('@/components/shared/recentlyAddedSection/partials/itemCard')
);

const blogListPagePath = '/expert-tips',
	viewAllButtonLabel = 'View All';

export default function RelatedBlogsSection(props) {
	const { classes } = useStyles(props);
	const isMobile = useSelector((state) => state.common.isMobile);

	let viewAllLink = blogListPagePath;
	if (!isEmpty(props.countries)) {
		viewAllLink = `${blogListPagePath}?countries=${encodeURIComponent(
			props.countries
		)}`;
	}

	const analyticsProps = {
		...props.analyticsProps,
		section: 'RELATED_BLOGS_SECTION',
	};

	let OnClickViewAll = () => {
		eventActions.trackEvent('LINK_CLICKED', {
			...analyticsProps,
			eventLabel: viewAllButtonLabel,
			eventCategory: 'NAVIGATION',
			eventAction: 'CLICKED',
			landingPageUrl: viewAllLink,
		});
	};

	const handleCardClick = (title, slug) => {
		eventActions.trackEvent('LINK_CLICKED', {
			...analyticsProps,
			eventCategory: 'NAVIGATION',
			eventAction: 'CLICKED',
			subSection: title,
			landingPageUrl: `/expert-tips/${slug}`,
		});
	};

	const BlogCard = isMobile ? BlogCardMobile : BlogCardDesktop;
	return (
		<Box className={classes.container}>
			<Box className={classes.blogListWrap}>
				{props.blogs
					.slice(0, isMobile ? 3 : props.blogs.length)
					.map((blog, index) => (
						<Box key={index} className={classes.blogCardWrap}>
							<Link href={`/expert-tips/${blog.slug}`}>
								<a onClick={() => handleCardClick(blog.title, blog.slug)}>
									<BlogCard
										title={blog.title}
										content={blog.description}
										image={blog.image}
										slug={blog.slug}
										location={blog.location}
										description={blog.description}
									/>
								</a>
							</Link>
						</Box>
					))}
			</Box>
			<Link href={viewAllLink}>
				<a className={classes.link}>
					<Button
						variant="outlined"
						color="primary"
						size="large"
						className={classes.button}
						endIcon={<ArrowRightAltIcon />}
						onClick={OnClickViewAll}
					>
						{viewAllButtonLabel}
					</Button>
				</a>
			</Link>
		</Box>
	);
}

RelatedBlogsSection.propTypes = {
	blogs: PropTypes.array.isRequired,
	backgroundColor: PropTypes.string,
	mobileBackgroundColor: PropTypes.string,
	tabletBackgroundColor: PropTypes.string,
	analyticsProps: PropTypes.object,
};
RelatedBlogsSection.defaultProps = {
	backgroundColor: '',
	mobileBackgroundColor: '',
	tabletBackgroundColor: '',
	analyticsProps: {},
};
